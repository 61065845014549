import fetch from 'common/js/fetch';

// 加载七牛token
export function getQiniuToken() {
    return fetch(630091);
}

// 获取阿里token
export function getAlToken() {
    return fetch('630091');
}