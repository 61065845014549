import { SYSTEM_CODE } from './config';
import cookies from 'browser-cookies';
import axios from 'axios';
import { clearUser, showErrMsg } from './util';

import Qs from 'qs';
const ERR_OK = '0';
const ERR_TIME_OUT = '4';

export default function fetch(code, param = {}) {
  var url = '/api';

  const data = {
    systemCode: SYSTEM_CODE,
    companyCode: SYSTEM_CODE,
    token: cookies.get('token') || '',
    ...param
  };
  // 新旧接口 请求方式不一样
  // console.log(param);
  if (param.isNew) {
    url = code;
    param = (param.method === 'get' ? data : Qs.stringify(data));
  } else {
    param = 'code=' + code + '&json=' + encodeURIComponent(JSON.stringify(data));
  }
  if(param.method === 'get') {
    return axios({
      headers: {
          'Content-Type': param.isNew ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded'
      },
      url: url,
      method: param.method || 'post',
      params: param
    }).then((res) => {
      res = res.data;
      if (res.errorCode === ERR_TIME_OUT) {
        logout();
        return Promise.reject('timeout');
      }
      if(res.errorCode !== ERR_OK) {
        if (res.errorInfo) {
          showErrMsg(res.errorInfo.toString());
        } else {
          showErrMsg('操作失败');
        }
        return Promise.reject(res.errorInfo);
      }
      return Promise.resolve(res.data);
    });
  }
  return axios({
    headers: {
        'Content-Type': param.isNew ? 'application/json;charset=UTF-8' : 'application/x-www-form-urlencoded'
    },
    url: url,
    method: param.method || 'post',
    data: param
  }).then((res) => {
    res = res.data;
    if (res.errorCode === ERR_TIME_OUT) {
      logout();
      return Promise.reject('timeout');
    }
    if(res.errorCode !== ERR_OK) {
      if (res.errorInfo) {
        if(url !== '/v2/backend/pay/withdraw/approve') {
          showErrMsg(res.errorInfo.toString());
        }
      } else {
        showErrMsg('操作失败');
      }
      return Promise.reject(res.errorInfo);
    }
    return Promise.resolve(res.data);
  });
}

export function logout() {
  clearUser();
  showErrMsg('登录超时，请重新登录!');
  window.location.href = '/login';
}
