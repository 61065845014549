import React from 'react';
import cookies from 'browser-cookies';
import { message, Modal } from 'antd';
import moment from 'moment';
import { PIC_PREFIX, DATE_FORMAT, MONTH_FORMAT, DATETIME_FORMAT, DATE_HOURS_M } from './config';
import './lib/BigDecimal';
import fetch from 'common/js/fetch';
import { getAlToken } from 'api/general';

/**
 * 保存用户登录信息
 * @param userId
 * @param token
 */
export function setUser({ userId, token }) {
  cookies.set('userId', userId);
  cookies.set('token', token);
}

// 删除用户登录信息
export function clearUser() {
  cookies.erase('userId');
  cookies.erase('token');
  cookies.erase('kind');
  cookies.erase('companyCode');
}

// 获取用户编号
export function getUserId() {
  return cookies.get('userId');
}

// 获取公司编号
export function getCompanyCode() {
  return cookies.get('companyCode');
}

// 设置用户角色信息
export function setRoleInfo({roleCode, kind, companyCode, type, loginName, inviteUrl}) {
  cookies.set('roleCode', roleCode);
  cookies.set('userName', loginName);
  if(inviteUrl) {
    cookies.set('inviteUrl', inviteUrl);
  }else{
    cookies.set('inviteUrl', '');
  }
  companyCode && cookies.set('companyCode', companyCode);
  kind && cookies.set('kind', kind);
  type && cookies.set('type', type);
}

// 获取用户角色编号
export function getRoleCode() {
  return cookies.get('roleCode');
}

// 获取用户username
export function getUserName() {
  return cookies.get('userName');
}

export function getinviteUrl() {
  return cookies.get('inviteUrl');
}

// 获取用户type
export function getUserType() {
  return cookies.get('type');
}

// 获取用户kind
export function getUserKind() {
  return cookies.get('kind');
}
export function getwhite(isWhitelistQUERY) {
    return cookies.get('isWhitelistQUERY');
}
/**
 * 通过正则表达式获取URL传递参数
 * @param name
 * @returns
 */
export function getQueryString(name, search) {
  search = search || window.location.search;
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = search.substr(1).match(reg);
  if (r !== null) {
    return decodeURIComponent(r[2]);
  }
  return '';
}

/**
 * 获取正确的url，使其以'/'开头
 * @param url
 */
export function getRealUrl(url) {
  if (url && url !== '#') {
    url = /^\//.test(url) ? url : '/' + url;
  }
  return url;
}

/**
 * 日期格式转化
 * @param date
 * @param fmt
 */
export function formatDate(date, fmt = 'yyyy-MM-dd') {
  if (isUndefined(date)) {
    return '-';
  }
  date = new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}
// 根据客户端时区显示时间 时间戳
export function formatDateTimeNew(date, fmt = 'yyyy-MM-dd') {
  if (isUndefined(date)) {
    return '-';
  }
  var offset = new Date().getTimezoneOffset() * 60 * 1000;
  date = new Date(date).getTime();
  var targetDate = new Date(date - offset);
  date = new Date(targetDate);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}
export function formatDateTime(date, fmt = 'yyyy-MM-dd') {
  if (isUndefined(date) || date === '0') {
    return '-';
  }
  var offset = new Date().getTimezoneOffset();
  var targetDate = new Date(date - offset);
  date = new Date(targetDate);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}
export function formatDateTimeM(date, fmt = 'yyyy-MM-dd') {
  if (isUndefined(date)) {
    return '-';
  }
  let offset = new Date().getTimezoneOffset();
  let targetDate = new Date((date * 1000) - offset);
  date = new Date(targetDate);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}
/**
 * 获取两位格式化数字
 * @param str
 */
export function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

/**
 * 日期格式转化 yyyy-MM-dd
 * @param date
 * @param format
 */
export function dateFormat(date) {
  return formatDate(date, 'yyyy-MM-dd');
}
export function dateFormatZone(date) {
  return formatDateTime(date, 'yyyy-MM-dd hh:mm:ss');
}
export function dateFormatYM(date) {
  return formatDateTime(date, 'yyyy-MM-dd');
}
export function dateFormatZoneM(date) {
  return formatDateTimeM(date, 'yyyy-MM-dd hh:mm:ss');
}
/**
 * 日期格式转化 yyyy-MM
 * @param date
 * @param format
 */
export function monthFormat(date) {
  date = formatDate(date, 'yyyy-MM-dd');
  let arr = date.split('-');
  arr.length = 2;
  date = arr.join('-');
  return date;
}

/**
 * 日期格式转化 yyyy-MM-dd hh:mm:ss
 * @param date
 * @param format
 */
export function dateTimeFormat(date) {
  return formatDateTimeNew(date, 'yyyy-MM-dd hh:mm:ss');
}

// 日期加法
export function addDate(date, days, format) {
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  return dateFormat(d.getFullYear() + '-' + m + '-' + d.getDate());
}

// 日期减法
export function mtDate(beginDate, days) {
  var beginDates = beginDate.split('-');
  var nDate = new Date(beginDates[1] + '-' + beginDates[2] + '-' + beginDates[0]);
  var millSeconds = Math.abs(nDate) - (days * 24 * 60 * 60 * 1000);
  var rDate = new Date(millSeconds);
  var year = rDate.getFullYear();
  var month = rDate.getMonth() + 1;
  if (month < 10) month = '0' + month;
  var date = rDate.getDate();
  if (date < 10) date = '0' + date;
  return (year + '-' + month + '-' + date);
}

// 日期加23时分秒
export function HhMmSsDate(date, days, format) {
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  return dateFormat(d.getFullYear() + '-' + m + '-' + d.getDate()) + ' 23:59:59';
}

// 日期加0时分秒
export function H0M0S0Date(date, days, format) {
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  return dateFormat(d.getFullYear() + '-' + m + '-' + d.getDate()) + ' 00:00:00';
}

/**
 * 金额格式转化 根据币种格式化金额
 * @param money 金额
 * @param format 小数点后几位
 * @param coin 币种
 * @param isRe 是否去零
 */
export function moneyFormat(money, format, coin, isRe = false, defUnit = '1000') {
    if(+money === 0) {
      return '0';
    }
    let unit = coin && getCoinData()[coin] ? getCoinUnit(coin) : defUnit;
    let flag = false;// 是否是负数
    if (isNaN(money)) {
        return '-';
    } else {
        money = Number(money);
    }
    if (money < 0) {
        money = -1 * money;
        flag = true;
    }
    // 如果有币种coin 则默认为8位  如果没有则默认格式为2位小数
    if (coin && isUndefined(format)) {
        format = 8;
    } else if (isUndefined(format) || typeof format === 'object') {
        format = 2;
    }
    // 金额格式化 金额除以unit并保留format位小数
    money = new BigDecimal(money.toString());
    money = money.divide(new BigDecimal(unit), format, MathContext.ROUND_DOWN).toString();
    // 是否去零
    if (isRe) {
        var re = /\d{1,3}(?=(\d{3})+$)/g;
        money = money.replace(/^(\d+)((\.\d+)?)$/, (s, s1, s2) => (s1.replace(re, '$&,') + s2));
    }
    let moneyArr = money.split('.');
    if(moneyArr[1]) {
      if(moneyArr[1].length > 7) {
        if(moneyArr[1] === '00000000') {
          if(moneyArr[0] === '0') {
            money = '0.00';
          }else {
            money = moneyArr[0] + '.00';
          }
        }
      }
    }else {
      money = moneyArr[0] + '.00';
    }
    if (flag) {
      money = '-' + money;
    }
    return money;
}
export function fmtNum(num, n) {
  // 保留到小数点后两位
  n = n ? parseInt(n) : 0;
  if(n < 0 || n === 0) {
    return Math.floor(num);
  }
  num = Number(Math.floor(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
	return num;
}

export function moneyBTC(money, format, coin, isRe = false) {
  let unit = coin && getCoinData()[coin] ? getCoinUnit(coin) : '1000000';
  let flag = false;// 是否是负数
  if (isNaN(money)) {
    return '-';
  } else {
    Number(money);
  }
  if (money < 0) {
    money = -1 * money;
    flag = true;
  }
  // 如果有币种coin 则默认为8位  如果没有则默认格式为2位小数
  if (coin && isUndefined(format)) {
    format = 8;
  } else if (isUndefined(format) || typeof format === 'object') {
    format = 2;
  }
  // 金额格式化 金额除以unit并保留format位小数
  money = new BigDecimal(money.toString());
  money = money.divide(new BigDecimal(unit), format, MathContext.ROUND_DOWN).toString();
  // 是否去零
  if (isRe) {
    var re = /\d{1,3}(?=(\d{3})+$)/g;
    money = money.replace(/^(\d+)((\.\d+)?)$/, (s, s1, s2) => (s1.replace(re, '$&,') + s2));
  }
  if (flag) {
    money = '-' + money;
  }
  return money;
}

/**
 * 金额放大 根据币种的单位把金额放大
 * @param money
 * @param format
 * @param coin 币种
 */
export function moneyParse(money, rate, coin) {
    let unit = coin && getCoinData()[coin] ? getCoinUnit(coin) : '1000';
    if (isUndefined(money)) {
        return '-';
    }
    rate = rate || new BigDecimal(unit);
    money = new BigDecimal(money);
    money = money.multiply(rate).toString();
    return money;
}

/**
 * 把格式化金额去掉逗号
 * @param money
 */
export function moneyReplaceComma(money) {
    return ('' + money).replace(/,/g, '');
}

// 判断是否是数字
export function isNumeric(value) {
    return !isNaN(Number(value));
}

/**
 * 金额减法
 * @param s1
 * @param s2
 * @param coin 币种
 * @param coinList 币种列表
 */
export function moneyFormatSubtract(s1, s2, format, coin, coinList) {
    if (!isNumeric(s1) || !isNumeric(s2)) {
        return '-';
    }
    let num1 = new BigDecimal(Number(s1).toString());
    let num2 = new BigDecimal(Number(s2).toString());
    return moneyFormat(num1.subtract(num2).toString(), format, coin, coinList);
}

/**
 * 金额乘法
 * @param s1
 * @param s2
 * @param coin 币种
 * @param coinList 币种列表
 */
export function moneyFormatMultiply(s1, s2, format, coin, coinList) {
    if (!isNumeric(s1) || !isNumeric(s2)) {
        return '-';
    }
    let num1 = new BigDecimal(Number(s1).toString());
    let num2 = new BigDecimal(Number(s2).toString());
    return moneyFormat(num1.multiply(num2).toString(), format, coin, coinList);
}

/**
 * 格式化文件地址
 * @param urls
 * @param suffix
 */
export function formatFile(urls, suffix = '') {
  if (!urls) {
    return '';
  }
  let url = urls.split(/\|\|/)[0];
  if (!/^http|^data:image/i.test(url)) {
    let index = url.indexOf('?imageMogr2');
    if (index !== -1) {
      suffix = url.substr(index);
      url = url.substr(0, index);
    }
    const qiniuDomain = localStorage.getItem('qiniu_domain') + '/';
    url = qiniuDomain + url + suffix;
  }
  return url;
}

/*
 * 格式化图片地址
 * @param imgs
 * @param suffix
 */
export function formatImg(imgs, suffix = '?imageMogr2/auto-orient/thumbnail/!300x300') {
  return formatFile(imgs, suffix);
}

/**
 * 是否是空
 * @param value
 */
export function isUndefined(value) {
  return value === undefined || value === null || value === '';
}

/**
 * 是否是函数
 * @param func
 */
export function isFunc(func) {
  return typeof func === 'function';
}

export function tempString(str, data) {
  return str.replace(/\{\{(\w+)\.DATA\}\}/gi, function(matchs) {
    var returns = data[matchs.replace(/\{\{(\w+)\.DATA\}\}/, '$1')];
    return isUndefined(returns) ? '' : returns;
  });
}

export function showMsg(msg, type = 'success', time = 2) {
  message[type](msg, time);
}

export function showWarnMsg(msg, time = 2) {
  showMsg(msg, 'warning', time);
}

export function showSucMsg(msg, time = 2) {
  showMsg(msg, 'success', time);
}

export function showErrMsg(msg, time = 2) {
  showMsg(msg, 'error', time);
}

export function showMsgfirm(okType, title, content, onOk, onCancel) {
  Modal.confirm({
    okType,
    title,
    content,
    okText: '确定',
    cancelText: '取消',
    onOk() {
      onOk && onOk();
    },
    onCancel() {
      onCancel && onCancel();
    }
  });
}

export function showConfirm({okType = 'primary', onOk, onCancel}) {
  Modal.confirm({
    okType,
    title: '您确定要删除该条记录吗?',
    content: '删除记录后无法还原',
    okText: '确定',
    cancelText: '取消',
    onOk() {
      onOk && onOk();
    },
    onCancel() {
      onCancel && onCancel();
    }
  });
}

export function showDelConfirm({onOk, onCancel}) {
  showConfirm({
    okType: 'danger',
    onOk,
    onCancel
  });
}
export function convertCurrency(currencyDigits) {
  if (isUndefined(currencyDigits)) {
    return '';
  }
  currencyDigits = moneyReplaceComma(currencyDigits);
  if (isNaN(currencyDigits)) {
    return '';
  }
  if(currencyDigits < 0) {
    currencyDigits = -currencyDigits;
  }
  var MAXIMUM_NUMBER = 99999999999.99;
  // Predefine the radix characters and currency symbols for output:
  var CN_ZERO = '零';
  var CN_ONE = '壹';
  var CN_TWO = '贰';
  var CN_THREE = '叁';
  var CN_FOUR = '肆';
  var CN_FIVE = '伍';
  var CN_SIX = '陆';
  var CN_SEVEN = '柒';
  var CN_EIGHT = '捌';
  var CN_NINE = '玖';
  var CN_TEN = '拾';
  var CN_HUNDRED = '佰';
  var CN_THOUSAND = '仟';
  var CN_TEN_THOUSAND = '万';
  var CN_HUNDRED_MILLION = '亿';
  var CN_DOLLAR = '元';
  var CN_TEN_CENT = '角';
  var CN_CENT = '分';
  var CN_INTEGER = '整';
  var integral; // Represent integral part of digit number.
  var decimal; // Represent decimal part of digit number.
  var outputCharacters; // The output result.
  var parts;
  var digits,
    radices,
    bigRadices,
    decimals;
  var zeroCount;
  var i,
    p,
    d;
  var quotient,
    modulus;
  currencyDigits = currencyDigits.toString();
  if (currencyDigits === '') {
    alert('请输入小写金额！');
    return '';
  }
  if (currencyDigits.match(/[^,.\d]/) !== null) {
    alert('');
    return '';
  }
  if ((currencyDigits).match(/^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/) == null) {
    alert('小写金额的格式不正确！');
    return '';
  }
  currencyDigits = currencyDigits.replace(/,/g, ''); // Remove comma delimiters.
  currencyDigits = currencyDigits.replace(/^0+/, ''); // Trim zeros at the beginning.
  // Assert the number is not greater than the maximum number.
  if (Number(currencyDigits) > MAXIMUM_NUMBER) {
    alert('金额过大，应小于1000亿元！');
    return '';
  }

  // Process the coversion from currency digits to characters:
  // Separate integral and decimal parts before processing coversion:
  parts = currencyDigits.split('.');
  if (parts.length > 1 && !/^0+$/.test(parts[1])) {
    integral = parts[0];
    decimal = parts[1];
    // Cut down redundant decimal digits that are after the second.
    decimal = decimal.substr(0, 2);
  } else {
    integral = parts[0];
    decimal = '';
  }
  // Prepare the characters corresponding to the digits:
  digits = [CN_ZERO, CN_ONE, CN_TWO, CN_THREE, CN_FOUR, CN_FIVE, CN_SIX, CN_SEVEN, CN_EIGHT, CN_NINE];
  radices = ['', CN_TEN, CN_HUNDRED, CN_THOUSAND];
  bigRadices = ['', CN_TEN_THOUSAND, CN_HUNDRED_MILLION];
  decimals = [CN_TEN_CENT, CN_CENT];
  // Start processing:
  outputCharacters = '';
  // Process integral part if it is larger than 0:
  if (Number(integral) > 0) {
    zeroCount = 0;
    for (i = 0; i < integral.length; i++) {
      p = integral.length - i - 1;
      d = integral.substr(i, 1);
      quotient = p / 4;
      modulus = p % 4;
      if (d === '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          outputCharacters += digits[0];
        }
        zeroCount = 0;
        outputCharacters += digits[Number(d)] + radices[modulus];
      }
      if (modulus === 0 && zeroCount < 4) {
        outputCharacters += bigRadices[quotient];
        zeroCount = 0;
      }
    }
    outputCharacters += CN_DOLLAR;
  }
  // Process decimal part if there is:
  if (decimal !== '') {
    for (i = 0; i < decimal.length; i++) {
      d = decimal.substr(i, 1);
      if (d !== '0') {
        outputCharacters += digits[Number(d)] + decimals[i];
      }
    }
  }
  // Confirm and return the final output string:
  if (outputCharacters === '') {
    outputCharacters = CN_ZERO + CN_DOLLAR;
  }
  if (decimal === '') {
    outputCharacters += CN_INTEGER;
  }
  return outputCharacters;
}
/**
 * 金额转换大写
 * @param money
 */
export function moneyUppercase(Num) {
  return convertCurrency(Num);
}

/**
 * 数字转换大写
 * @param number
 */
export function numUppercase(Num) {
  let newNum = convertCurrency(Num);
  newNum = newNum.replace(/元|整/gi, '');
  return newNum;
}

/**
 * 四舍五入保留2位小数（不够位数，则用0替补）
 * @param number
 */
export function keepTwoDecimalFull(num) {
  let number = Math.round(num * 100) / 100;
  return number;
}

// 空函数
export const noop = () => {};

// 获取详情页面控件校验规则
export const getRules = (item) => {
  let rules = [];
  if (item.required && !item.hidden) {
    rules.push({
      required: true,
      message: '必填字段'
    });
  }
  if (item.zeroTwoOne) {
    rules.push({
      pattern: /^(0.\d+|0|1)$/,
      message: '数值格式不正确'
    });
  }
  if (item.email) {
    rules.push({
      type: 'email',
      message: '请输入正确格式的电子邮件'
    });
  }
  if (item.mobile) {
    rules.push({
      pattern: /^1[3|4|5|6|7|8|9]\d{9}$/,
      message: '手机格式不对'
    });
  }
  if (item.integer) {
    rules.push({
      pattern: /^[1-9]\d*$/,
      message: '请输入正整数'
    });
  }
  if (item.number) {
    rules.push({
      pattern: /^-?\d+(\.\d+)?$/,
      message: '请输入合法的数字'
    });
  }
  if (item.positive) {
    rules.push({
      pattern: /^\d+(\.\d+)?$/,
      message: '请输入正数'
    });
  }
  if (item.integer) {
    rules.push({
      pattern: /^-?\d+$/,
      message: '请输入整数'
    });
  }
  if (item.shijian) {
    rules.push({
      pattern: /^(2[0-3]|[0-1]?\d|\*|-|\/)$/,
      message: '请输入0-23的整数'
    });
  }
  if (item.idCard) {
    rules.push({
      pattern: /^([1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3})|([1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x))$/,
      message: '请输入合法的身份证号'
    });
  }
  if (item.bankCard) {
    rules.push({
      pattern: /^([1-9]{1})(\d{13,19})$/,
      message: '请输入合法的银行卡号'
    });
  }
  if (item.amount) {
    rules.push({
      pattern: /^\d+(?:\.\d{1,2})?$/,
      message: '金额必须>=0，且小数点后最多2位'
    });
  }

  if (item.min) {
    rules.push({
      validator: (rule, value, callback) => {
        let reg = /^-?\d+(\.\d+)?$/.test(value);
        if (reg && value && Number(value) < Number(item.min)) {
          let error = `请输入一个最小为${item.min}的值`;
          callback(error);
        } else {
          callback();
        }
      }
    });
  }

  if (item.max) {
    rules.push({
      validator: (rule, value, callback) => {
        let reg = /^-?\d+(\.\d+)?$/.test(value);
        if (reg && value && Number(value) > Number(item.max)) {
          let error = `请输入一个最大为${item.max}的值`;
          callback(error);
        } else {
          callback();
        }
      }
    });
  }
  if (item.xshu) {
      rules.push({
          validator: (rule, value, callback) => {
              let reg = /^-?\d+(\.\d+)?$/.test(value);
              if (reg && value && (Number(value) < 0 || Number(value) > 1)) {
                  let error = `请输入一个0-1的值`;
                  callback(error);
              } else {
                  callback();
              }
          }
      });
  }

  if (item.maxlength) {
    rules.push({
      min: 1,
      max: item.maxlength,
      message: `请输入一个长度最多是${item.maxlength}的字符串`
    });
  }
  return rules;
};

// 获取修改、详情页每个输入框的真实值
export const getRealValue = ({ pageData, field, type, _keys, value, rangedate,
  multiple, formatter, amount, amountRate, cFields, readonly, listCode, selectData, isPoints }) => {
  let result;
  pageData = isUndefined(pageData) ? {} : pageData;
  result = pageData[field];
  try {
    if (_keys) {
      result = getValFromKeys(_keys, pageData, type);
    }
    if (!isUndefined(value) && !result) {
      result = value;
    }
    if (type === 'citySelect') {
      result = getCityVal(_keys, cFields, result, pageData);
    } else if (type === 'date' || type === 'datetime' || type === 'month' || type === 'datetimeLong') {
      result = getRealDateVal(pageData, type, result, _keys, readonly, rangedate, isPoints);
    } else if (type === 'checkbox') {
      result = getRealCheckboxVal(result);
    } else if (multiple) {
      result = result ? result.split(',') : [];
    } else if (type === 'o2m') {
      if (listCode) {
        result = isUndefined(result) ? selectData[field] : result;
      }
      result = result || [];
    }
    if (formatter) {
      result = formatter(result, pageData);
    } else if (amount) {
      result = isUndefined(result) ? '' : moneyFormat(result, amountRate);
    }
  } catch (e) {}
  return isUndefined(result) ? '' : result;
};

// 通过_keys获取真实值
function getValFromKeys(keys, pageData, type) {
  let _value = {...pageData};
  let emptyObj = {};
  keys.forEach(key => {
    _value = isUndefined(_value[key]) ? emptyObj : _value[key];
  });
  return _value === emptyObj
    ? (type === 'checkbox' || type === 'citySelect' || type === 'o2m')
      ? [] : ''
    : _value;
}

// 获取城市的真实值
function getCityVal(keys, cFields, result, pageData) {
    let cData = keys && result ? result : pageData;
    let prov = cData[cFields[0]];
    if (prov) {
        let city = cData[cFields[1]] ? cData[cFields[1]] : '全部';
        let area = cData[cFields[2]] ? cData[cFields[2]] : '全部';
        result = [prov, city, area];
    } else {
        result = [];
    }
    return result;
}

// 获取日期真实值
function getRealDateVal(pageData, type, result, keys, readonly, rangedate, isPoints) {
    let format = type === 'date' ? DATE_FORMAT : type === 'month' ? MONTH_FORMAT : DATETIME_FORMAT;
    let fn = type === 'date' ? dateFormat : type === 'month' ? monthFormat : type === 'datetimeLong' ? dateFormatZone : dateTimeFormat;
    if (readonly) {
      if(typeof result === 'string') {
        return rangedate
        ? getRangeDateVal(rangedate, keys, result, format, fn, pageData, readonly)
        : result ? fn(result, format) : null;
      }else {
        return rangedate
        ? getRangeDateVal(rangedate, keys, result, format, fn, pageData, readonly)
        : result ? dateFormatZone(result) : null;
      }
    }
    if(typeof result === 'string') {
      return rangedate
      ? getRangeDateVal(rangedate, keys, result, format, fn, pageData)
      : result ? moment(dateTimeFormat(result), format) : null;
    }else {
      return rangedate
      ? getRangeDateVal(rangedate, keys, result, format, fn, pageData)
      : result ? moment(dateFormatZone(result), format) : null;
    }
}

// 获取范围日期真实值
function getRangeDateVal(rangedate, keys, result, format, fn, pageData, readonly) {
    let dates = keys && result ? result : pageData;
    let start = dates[rangedate[0]];
    let end = dates[rangedate[1]];
    if (readonly) {
      return start ? fn(start, format) + '~' + fn(end, format) : null;
    }
    return start ? [moment(fn(start), format), moment(fn(end), format)] : null;
}

// 获取checkbox的真实值
function getRealCheckboxVal(result) {
  let arr = [];
  if(result) {
    arr = result.split('||');
    arr = arr.map(item => +item);
    return arr;
  }else {
    return [];
  }
}

/**
 * 获取币种Data
 * return {
 *  'BTC': {
 *      'coin': 'BTC',
 *      'unit': '1e8',
 *      'name': '比特币',
 *      'type': '0',
 *      'status': '0'
 *  }
 *}
 */
export function getCoinData() {
    return JSON.parse(sessionStorage.getItem('coinData'));
}
export function getObjectData() {
  return JSON.parse(sessionStorage.getItem('objectData'));
}

/**
 * 获取币种列表
 * return [{
 *      key: 'BTC',
 *      value: '比特币'
 *}]
 */
export function getCoinList() {
    return JSON.parse(sessionStorage.getItem('coinList'));
}

export function getItemObjectList() {
  return JSON.parse(sessionStorage.getItem('objectList'));
}
export function getItemAccountList() {
  return JSON.parse(sessionStorage.getItem('accountcfgList'));
}

// 获取币种unit
export function getCoinUnit(coin) {
    if (!coin) {
        console.log('coin不能为空');
        return;
    }
    var unit = getCoinData()[coin].unit;
    return unit;
}

export function getCoinType(coin) {
  if (!coin) {
      console.log('coin不能为空');
      return;
  }
  var type = getCoinData()[coin].type;
  return type;
}

/**
 * 查询字典对应的dValue
 */
export function findDsct(array, value) {
  return array.find(item => item.dkey === value).dvalue;
}

export function toNumberStr(num, digits) {
// 正则匹配小数科学记数法
  // /^(2[0-3]|[0-1]?\d|\*|-|\/)$/
  if (/^(\d+(?:\\.\d+)?)(e)([\\-]?\d+)$/.test(num)) {
// 正则匹配小数点最末尾的0
    var temp = /^(\d{1,}(?:,\d{3})*\.(?:0*[1-9]+)?)(0*)?$/.exec(num.toFixed(digits));
    if(temp) {
      return temp[1];
    }else{
      return num.toFixed(digits);
    }
  }else{
    return '' + num;
  }
}

// cTable 处理表格单元组件
export function toDealHtml(restProps) {
    const {children, render, type, record, dataIndex} = restProps;
    if(render && typeof render === 'function') {
      return render();
    } else if (type === 'img' || type === 'file') {
      const fileList = dealTableImg(record[dataIndex]);
      const FileHtml = function (props) {
          const {item = {}} = props;
          if(type === 'img') {
            return <img style={{width: '50px', marginLeft: '10px'}} src={item.url} alt={item.name}/>;
          }
          return <a target="_blank" download={item.name} style={{marginLeft: '10px'}} href={item.url}>{item.name}</a>;
      };
      return fileList.map(item => <FileHtml key={item.uid} item={item}/>);
    }
    return children;
}

export function dealTableImg(vv) {
    let fileList = [];
    if(vv && typeof vv === 'string') {
        fileList = vv.split('||').map(key => ({
            key,
            uid: key,
            name: key,
            status: 'done',
            url: formatFile(key),
            thumbUrl: formatFile(key)
        }));
    }else if (Array.isArray(vv)) {
        fileList = vv;
    }else if (vv.fileList) {
        fileList = vv.fileList.map(item => {
            const {uid, name, response = {}} = item;
            return {
                uid,
                name,
                status: 'done',
                url: formatFile(response.key),
                thumbUrl: formatFile(response.key)
            };
        });
    }
    return fileList;
}

export function setWebIcon(link) {
  let $favicon = document.querySelector('link[rel="icon"]');
  if ($favicon !== null) {
    $favicon.rel = 'icon';
    $favicon.type = 'image/x-icon';
    $favicon.href = link;
  } else {
    $favicon = document.createElement('link');
    $favicon.rel = 'icon';
    $favicon.type = 'image/x-icon';
    $favicon.href = link;
    document.head.appendChild($favicon);
  }
}

export async function setSystem() {
  let headLogo = '';
  let loginPic = '';
  let webIcon = '';
  const SYSTEM_CODE = localStorage.getItem('SYSTEM_CODE');
  await fetch(660918, {type: 'oss_config'}).then(async ossData => {
    headLogo = ossData.head_logo;
    document.title = ossData.web_name;
    localStorage.setItem('SYSTEM_CODE', ossData.system_code);
    localStorage.setItem('webName', ossData.web_name);
    await getAlToken().then(data => {
      if (data) {
        loginPic = `${data.filePath}/${ossData.login_logo}`;
        webIcon = `${data.filePath}/${ossData.web_logo}`;
        localStorage.setItem('alToken', JSON.stringify(data));
        localStorage.setItem('webIcon', webIcon);
        localStorage.setItem('loginPic', loginPic);
        localStorage.setItem('qiniu_domain', data.filePath);
        let link = `${data.filePath}/${headLogo}`;
        setWebIcon(link);
        localStorage.setItem('headLogo', link);
      }
    });
  }).catch(err => {
    alert(err);
  });
  // const mallOssUrl = sessionStorage.getItem('mallOssUrl');
  // if (!mallOssUrl) {
  //   fetch(625013, {key: 'mall_oss_url', start: 1, limit: 10}).then(data => {
  //     if (data.list[0]) {
  //       sessionStorage.setItem('mallOssUrl', data.list[0].value);
  //     }
  //   });
  // }
  // const apiLoginUrl = sessionStorage.getItem('apiLoginUrl');
  // if (!apiLoginUrl) {
  //   fetch(625013, {key: 'igo_oss_url', start: 1, limit: 10}).then(data => {
  //     if (data.list[0]) {
  //       sessionStorage.setItem('apiLoginUrl', data.list[0].value);
  //     }
  //   });
  // }
  // const minerOssUrl = sessionStorage.getItem('minerOssUrl');
  // if (!minerOssUrl) {
  //   fetch(625013, {key: 'miner_oss_url', start: 1, limit: 10}).then(data => {
  //     if (data.list[0]) {
  //       sessionStorage.setItem('minerOssUrl', data.list[0].value);
  //     }
  //   });
  // }
  // const gravitationalOssUrl = sessionStorage.getItem('gravitationalOssUrl');
  // if (!gravitationalOssUrl) {
  //   fetch(625013, {key: 'gravity_oss_url', start: 1, limit: 10}).then(data => {
  //     if (data.list[0]) {
  //       sessionStorage.setItem('gravitationalOssUrl', data.list[0].value);
  //     }
  //   });
  // }
}