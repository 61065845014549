// export const SYSTEM_CODE = 'COWEX';
export const SYSTEM_CODE = 'CD-EXCHANGE';
// 七牛http上传地址
// export const UPLOAD_URL = 'http://up.qiniup.com';
// 七牛https上传地址
export const UPLOAD_URL = 'https://up.qiniup.com';
// 阿里云图片前缀
export const PIC_PREFIX = 'http://picture.aismile.cn/';
export const PIC_BASEURL_L = '?imageMogr2/auto-orient';
// 系统userid
export const SYS_USER = 'SYS_USER';

// 系统根菜单编号
export const ROOT_MENU_CODE = 'COINSM201700000000000000';
// // 导航根菜单编号
//  export const ROOT_APP_CODE = 'DH201810120023250000000';

// FMVP 币种
export const CION_FMVP = 'FMVP';

export const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16}
    }
};
export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
};

export const tailFormItemLayout1 = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 19,
            offset: 5
        }
    }
};

export const lineFormItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 18}
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20}
    }
};

// 定义 validateFieldsAndScroll 的滚动行为
export const validateFieldsAndScrollOption = {
    scroll: {
        offsetTop: 110
    }
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_FORMAT = 'YYYY-MM';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_HOURS = 'YYYY-MM-DD HH';
export const DATE_HOURS_M = 'YYYY-MM-DD HH:mm';

// 项目基本配置
export const PROJECT_CONFIG = [{
    projectId: 'abiwang',
    name: 'A币网',
    logo: require('../../images/abi_logo.png'),
    topPic: require('../../images/abi_top_icon.png'),
    faviconPic: require('../../images/favicon_abi.png')
}, {
    projectId: 'frtc',
    name: '五方交易所',
    logo: require('../../images/wuf_logo.png'),
    topPic: require('../../images/wuf_top_pic.png'),
    faviconPic: require('../../images/wuf_favicon.png')
}, {
    projectId: 'cowex',
    name: '奶牛基所',
    logo: require('../../images/logo.png'),
    topPic: require('../../images/head_logo.png'),
    faviconPic: require('../../images/favicon.jpg')
}];
