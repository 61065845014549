import {combineReducers} from 'redux';
import {user} from './redux/user';
import {menu} from './redux/menu';
import {message} from './redux/message';
import {modalDetail} from './redux/modal/build-modal-detail';
import {systemRole} from './redux/system/role';
import {systemMenu} from './redux/system/menu';
import {systemSysParam} from './redux/system/sysParam';
import {systemUser} from './redux/system/user';
import {systemPwdReset} from './redux/system/pwdReset';
import {systemDataDict} from './redux/system/dataDict';
import {publicAboutus} from './redux/public/aboutus';
import {publicQuantitative} from './redux/public/quantitative';
import {publicContact} from './redux/public/contact';
import {publicMachineProtocol} from './redux/public/machineProtocol';
import {publicBuyADS} from './redux/public/buyADS';
import {publicSellETH} from './redux/public/sellETH';
import {publicTransactionRemind} from './redux/public/TransactionRemind';
import {publicCommunity} from './redux/public/community';
import {publicCommunityAddEdit} from './redux/public/community-addedit';
import {publicWarmReminding} from './redux/public/warmReminding';
import {publicLegalDeclaration} from './redux/public/legalDeclaration';
import {publicRateExplain} from './redux/public/rateExplain';
import {publicLhlcxy} from './redux/public/lhlcxy';
import {businessSimulateList} from './redux/binary/orderManage/simulateList';
import {businessFirmlateList} from './redux/binary/orderManage/firmlateList';
import {businessSampleList} from './redux/binary/orderManage/sampleList';

import {historySimulateList} from './redux/binary/historyManage/simulateList';
import {historyFirmlateList} from './redux/binary/historyManage/firmlateList';
import {historySampleList} from './redux/binary/historyManage/sampleList';
import {varietyGrouping} from './redux/binary/varietyManage/varietyGrouping';
import {broadcastMsg} from './redux/public/broadcast';
import {feed} from './redux/public/feed';
import {varietyOdds} from './redux/public/varietyOdds';

/* 业务管理 */
// 客户管理
// 会员查询
import {userCustomer} from './redux/user/customer/customer';
import {userCustomerAccount} from './redux/user/customer/customer-account';
import {userCustomerEntrustQuery} from './redux/user/customer/customer-entrustQuery';
import {userCustomerLedgerQuery} from './redux/user/customer/customer-ledgerQuery';
import {userCustomerAccountSummary} from './redux/user/customer/customer-accountSummary';
import {userCustomerIdentify} from './redux/user/customer/identify';

// 业务管理-账号管理
import {accountMarketmakers} from './redux/business/account/marketmakers';
import {accuntMarketmakersLowerLevelQuery} from './redux/business/account/marketmakers-lowerLevelQuery';

// 账号管理-项目方管理
import {businessProjectManage} from './redux/business/projectManage/projectManage';

// 币币交易-交易规则
import {businessOrderInquiry} from './redux/business/orderInquiry/orderInquiry';

// 币币交易-节假日管理
import {businessHolidaySeason} from './redux/business/holidaySeason/holidaySeason';

// 币币交易-委托单查询
import {businessTradingRules} from './redux/business/tradingRules/tradingRules';
import {businessTradingRulesFund} from './redux/business/tradingRulesFund/tradingRulesFund';
import {businessHisTradingRules} from './redux/business/hisTradingRules/hisTradingRules';
import {businessHisTradingRulesFund} from './redux/business/hisTradingRulesFund/hisTradingRulesFund';
import {businessLineTradingRules} from './redux/business/hisTradingRules/lineTradingRules';
import {businessLineTradingRulesFund} from './redux/business/hisTradingRulesFund/lineTradingRulesFund';
import {businessLineRules} from './redux/business/lineRules/lineRules';
import {businessLineRulesFund} from './redux/business/lineRulesFund/lineRulesFund';

// 币币交易-成交单查询
import {businessTransactionOrderInquiry} from './redux/business/transactionOrderInquiry/transactionOrderInquiry';
import {businessTransactionOrderInquiryFund} from './redux/business/transactionOrderInquiryFund/transactionOrderInquiryFund';

import {businessLineOrderInquiry} from './redux/business/transactionOrderInquiry/lineOrderInquiry';
import {businessLineOrderInquiryFund} from './redux/business/transactionOrderInquiryFund/lineOrderInquiryFund';

import {businessTradingSet} from './redux/business/tradingSet/tradingSet';

import {purchaseSettlementRecord} from './redux/business/purchase/settlementRecord';

import {businessPurchaseRule} from './redux/business/purchaseRule/purchaseRule';

// 申购管理
import {purchasePurchase} from './redux/business/purchase/purchase';
import {purchasePurchaseRecord} from './redux/business/purchase/purchaseRecord';

// 交割管理-物权
import {deliveryRealRight} from './redux/business/delivery/realRight';
// 到期交割
import {deliveryDelivery} from './redux/business/delivery/delivery';
import {deliveryDeliveryRecord} from './redux/business/delivery/deliveryRecord';
import {businessDeliveryRule} from './redux/business/deliveryRule/deliveryRule';
import {deliverys} from './redux/business/delivery/deliverys';

// 待结算佣金名单
import {userChannelDealerCommissions} from './redux/user/channelDealerCommissions/channelDealerCommissions';
import {userChannelDealerCommissionsChecklist} from './redux/user/channelDealerCommissions/channelDealerCommissions-checklist';
import {userChannelDealerCommissionsSettlement} from './redux/user/channelDealerCommissions/channelDealerCommissions-settlement';

// 待结算佣金名单
import {userChannelDealerSettleHistory} from './redux/user/channelDealerCommissions/channelDealerSettleHistory';
import {userChannelDealerSettleHistoryCommissions} from './redux/user/channelDealerCommissions/channelDealerSettleHistory-commissions';

// 历史分红名单
import {userHistoryDivideList} from './redux/user/historyDivideList/historyDivideList';
import {userHistoryDivideListDivideList} from './redux/user/historyDivideList/historyDivideList-divideList';

// 佣金结算历史
import {userCommissionsHistoryList} from './redux/user/commissionsHistoryList/commissionsHistoryList';
import {userCommissionsHistoryListCommissions} from './redux/user/commissionsHistoryList/commissionsHistoryList-commissions';

// 黑名单管理
import {userCustomerBlackList} from './redux/user/customer/customer-blackList';

// kyc审核
import {userKycCheck} from './redux/user/kycCheck/kycCheck';

// 资料审核
import {userDataCheck} from './redux/user/dataCheck/dataCheck';

// OTC交易管理
// 购买交易
import {tradeBuyTrade} from './redux/trade/buyTrade/buyTrade';

// 交易账户
import {tradeAccount} from './redux/trade/tradeAccount/tradeAccount';

// 委托单
import {externalOrder} from './redux/trade/tradeAccount/externalOrder';

// 历史委托单
import {externalHistoricalOrder} from './redux/trade/tradeAccount/externalHistoricalOrder';

// 成功委托单
import {successfulOrder} from './redux/trade/tradeAccount/successfulOrder';

// 默认评论设置
import {defaultCommit} from './redux/trade/defaultCommit/defaultCommit';

// 出售交易
import {tradeSaleTrade} from './redux/trade/saleTrade/saleTrade';

// OTC订单管理
// 进行中订单
import {tradeUnderWayOrder} from './redux/trade/underWayOrder/underWayOrder';

// 已结束订单
import {tradeFinishOrder} from './redux/trade/finishOrder/finishOrder';

// 仲裁订单管理
// 仲裁订单
import {tradeArbitrationOrder} from './redux/trade/arbitrationOrder/arbitrationOrder';

// 仲裁通知人
import {tradeArbitrationNotifier} from './redux/trade/arbitrationNotifier/arbitrationNotifier';

// 业务规则
// 广告费规则
import {rulesAdvertisingFee} from './redux/rules/advertisingFee/advertisingFee';

// 提币手续费规则
import {BTCFinanceWithdrawRule} from './redux/BTC-finance/withdrawRule/withdrawRule';

// 业务管理--评论管理
import {commentKeywords} from './redux/biz/comment/keywords';
import {commentCheck} from './redux/biz/comment/check';
import {commentList} from './redux/biz/comment/list';
// 应用列表管理
import {bizApplicationList} from './redux/biz/applicationList/applicationList';
import {bizApplicationListHelpSet} from './redux/biz/applicationList/applicationListHelpSet';

// 红包管理
import {bizReceiveQuery} from './redux/biz/redPacket/receiveQuery';
import {bizRedPacketQuery} from './redux/biz/redPacket/redPacketQuery';

// 功能项管理
import {operationsFeatureItem} from './redux/biz/quotation/featureItem';

/* BTC财务管理 */
// 查询地址
import {BTCFinancePlatformAccount} from './redux/BTC-finance/platformAccount/platformAccount';
// 平台账户
import {addressQuery} from './redux/BTC-finance/addressQuery';

// 分发地址
import {BTCFinanceDiviAddress} from './redux/BTC-finance/diviAddress/diviAddress';
import {BTCFinanceStockLockCustomer} from './redux/BTC-finance/diviAddress/stockLockCustomer';
import {BTCFinanceLineCustomer} from './redux/BTC-finance/diviAddress/lineCustomer';
import {BTCFinanceFinancialAccount} from './redux/BTC-finance/diviAddress/financialAccount';
import {BTCFinanceDiviAddressLedger} from './redux/BTC-finance/diviAddress/diviAddress-ledger';

// 充币管理
// 线下充值
import {BTCFinanceOfflineRecharge} from './redux/BTC-finance/offlineRecharge/offlineRecharge';
import {BTCFinanceOfflineRechargeAddEdit} from './redux/BTC-finance/offlineRecharge/offlineRecharge-addedit';
import {BTCFinanceOfflineRechargeDetail} from './redux/BTC-finance/offlineRecharge/offlineRecharge-detail';

// 充值查询
import {BTCFinanceOfflineRechargeQuery} from './redux/BTC-finance/offlineRecharge/offlineRechargeQuery';

// 提币管理
// 提币地址
import {BTCFinanceTBAddress} from './redux/BTC-finance/TBAddress/TBAddress';
import {TradeArbitrationNotifier} from './redux/BTC-finance/arbitrationNotifier/arbitrationNotifier';
import {UserStatistics} from './redux/userStatistics/userStatistics';
import {InUserStatistics} from './redux/userStatistics/InUserStatistics';
import {userStatisticsSummarize} from './redux/userStatistics/summarize';
import {userStatisticsOrderStatistics} from './redux/userStatistics/orderStatistics';
import {userVirtualUserPool} from './redux/user/virtualUserPool/virtualUserPool';
import {tradeConfiguration} from './redux/trade/absRule/configuration';
import {textConfigConfiguration} from './redux/trade/textConfig/configuration';

// 线下提币
import {BTCFinanceTBunderline} from './redux/BTC-finance/TBunderline/TBunderline';
import {BTCFinanceTBunderlineAddEdit} from './redux/BTC-finance/TBunderline/TBunderline-addedit';
import {BTCFinanceTBunderlineMultiCheck} from './redux/BTC-finance/TBunderline/TBunderline-multiCheck';
import {publicNotice} from './redux/public/notice';
import {publicNoticeAddEdit} from './redux/public/notice-addedit';
import {publicFirebase} from './redux/public/firebase';
import {publicFirebaseAddEdit} from './redux/public/firebase-addedit';
// OTC 国家设置

import {publicOtcCountry} from './redux/public/otccountry';
import {quanTitativeCategory} from './redux/public/quantitativecategory';
import {GoSettlement} from './redux/marketsettlement/gosettlement';

// OTC 支付方式设置
import {publicOtcPayment} from './redux/public/otcpayment';
import {publicChannelBank} from './redux/public/channelbank';
// 业务管理-- OTC管理
import {OtcManageAdvert} from './redux/otcmanage/advert';
import {OtcManageCurrencyRules} from './redux/otcmanage/currencyRules';
import {OtcSurvivaLorDerOrderedit} from './redux/otcmanage/survivalorder-orderedit';
import {OtcSurvivaLorDer} from './redux/otcmanage/survivalorder';
import {OtcManageHistoricalorder} from './redux/otcmanage/historicalorder';
import {OtcManageHistoricalorderEdit} from './redux/otcmanage/historicalorder-orderedit';
import {OtcManageComment} from './redux/otcmanage/comment';
// 归集管理
import {BTCFinanceGJAddress} from './redux/BTC-finance/GJAddress/GJAddress';
import {BTCFinanceGJAddressAddEdit} from './redux/BTC-finance/GJAddress/GJAddress-addedit';
import {BTCFinanceGJAddressQuery} from './redux/BTC-finance/GJAddressQuery/GJAddressQuery';
import {BTCFinanceGJAddressQueryAddEdit} from './redux/BTC-finance/GJAddressQuery/GJAddressQuery-addedit';
import {publicBanner} from './redux/public/banner';
import {publicBannerAddEdit} from './redux/public/banner-addedit';
// 转账管理
import {internalTransferEx} from './redux/transferManagement/internalTransferEx';
// 转账管理
import {internalTransferSlt} from './redux/transferManagement/internalTransferSlt';
// 转账管理
import {transferNotThrough} from './redux/transferManagement/transferNotThrough';
import {TransferTransferRules} from './redux/transferManagement/transferRules';
// 币种管理
import {publicSectorManagement} from './redux/public/sectorManagement';
import {publicCurrencyManagement} from './redux/public/currencyManagement';
import {publicMarketManagement} from './redux/public/marketManagement';
import {publicTransactionPair} from './redux/public/transactionPair';
// 点位管理
import {publicPointPostion} from './redux/public/pointpostion';
// 补给地址
import {BTCFinanceSupplyAddress} from './redux/BTC-finance/supplyAddress/supplyAddress';
import {BTCFinanceDiviAddressAddedit} from './redux/BTC-finance/diviAddress/diviAddressAddedit';
// 业务管理-- 营销管理
import {StadySettLement} from './redux/marketsettlement/stadysettlement';
import {NoOrder} from './redux/marketsettlement/noorder';
import {StayOrder} from './redux/marketsettlement/stayorder';
import {AlreadySettlement} from './redux/marketsettlement/alreadysettlement';
// 统计分析-- 监控管理 - triggers监控
import { timerMonitor } from './redux/timerManager/timerMonitor/timerMonitor';
// 统计分析-- 监控管理 - triggers性能
import { timerPerformance } from './redux/timerManager/timerPerformance/timerPerformance';
// 基础数据管理-- 法币汇率配置
import {legalTender} from './redux/biz/quotation/legalTender';
import {legalTenderHistory} from './redux/biz/quotation/legalTenderHistory';

// 营销管理
import {bigManagementDigSet} from './redux/marketing/digManagement/digSet';
import {bigManagementDigBase} from './redux/marketing/digManagement/digBase';
import {bigManagementDigRecord} from './redux/marketing/digManagement/digRecord';
import {digRecordDetailList} from './redux/marketing/digManagement/digRecordDetailList';
import {bigManagementDigLicense} from './redux/marketing/digManagement/digLicense';
import {bigManagementDigDelivery} from './redux/marketing/digManagement/digDelivery';
import {bigManagementCalculate} from './redux/marketing/digManagement/calculate';
import {bigManagementTokensAList} from './redux/marketing/digManagement/tokensAList';
import {marketingWillExamination} from './redux/marketing/settlement/willExamination';
import {marketingThroughExamination} from './redux/marketing/settlement/throughExamination';
import {marketingNotThroughExamination} from './redux/marketing/settlement/notThroughExamination';
import {marketingLqqRobot} from './redux/marketing/lqqRobot/lqqRobot';
import {marketingSyrfp} from './redux/marketing/syrfp/syrfp';
import {orePondManagement} from './redux/OrePondManagement/OrePondManagement';
import {userOrePondManagement} from './redux/OrePondManagement/userOrePondManagement';
import {purchaseRecordTwo} from './redux/business/purchase/purchaseRecordTwo';
import {commissionRefund} from './redux/user/commissionRefund/commissionRefund';

// 业务统计
// 平台日充提量
import {latformDaily} from './redux/businessStatistics/latformDaily';

// 用户日充提量
import {userDaily} from './redux/businessStatistics/userDaily';

// 平台日币量
import {latformDailyCoin} from './redux/businessStatistics/latformDailyCoin';

// 用户日币量
import {userDailyCoin} from './redux/businessStatistics/userDailyCoin';

// 平台日交易量
import {latformDailyTrade} from './redux/businessStatistics/latformDailyTrade';

// 用户日交易量
import {userDailyTrade} from './redux/businessStatistics/userDailyTrade';

import {latformDailyNew} from './redux/statistics/latformDaily';
import {userDailyNew} from './redux/statistics/userDaily';
import {latformDailyCoinNew} from './redux/statistics/latformDailyCoin';
import {userDailyCoinNew} from './redux/statistics/userDailyCoin';
import {userDailyTradeNew} from './redux/statistics/userDailyTrade';
import {payChargeDailyNew} from './redux/statistics/payChargeDaily';
import {configurationList} from './redux/binary/conf/configurationList';
// API管理
import {apikeyManager} from './redux/apikeyManager/apikeyManager';

import {fixedDeposit} from './redux/BTC-finance/fixedDeposit/fixedDeposit';

import {supply} from './redux/BTC-finance/supply/supply';

// 机器人管理
import {klineGroup} from './redux/robotManagement/klineGroup/klineGroup';
import {maintainDetails} from './redux/robotManagement/maintainDetails/maintainDetails';
import {executeInstruction} from './redux/robotManagement/executeInstruction/executeInstruction';
import {robotManagementCommissionOrder} from './redux/robotManagement/commissionOrder/commissionOrder';
import {robotManagementCommissionOrder2} from './redux/robotManagement/commissionOrder/commissionOrder2';
import {robotManagementShoppingSpree} from './redux/robotManagement/shoppingSpree/shoppingSpree';
import {orderInquiry} from './redux/robotManagement/orderInquiry/orderInquiry';

// 合约交易
import {contractAccount} from './redux/contractualTransactions/contractAccount/contractAccount';
import {currentBinQuery} from './redux/contractualTransactions/currentBinQuery/currentBinQuery';
import {historicalBinQuery} from './redux/contractualTransactions/historicalBinQuery/historicalBinQuery';
import {delegationOrderQuery} from './redux/contractualTransactions/delegationOrderQuery/delegationOrderQuery';
import {delegationOrderQueryFund} from './redux/contractualTransactions/delegationOrderQueryFund/delegationOrderQueryFund';
import {hisDelegationOrderQuery} from './redux/contractualTransactions/hisDelegationOrderQuery/hisDelegationOrderQuery';
import {hisDelegationOrderQueryFund} from './redux/contractualTransactions/hisDelegationOrderQueryFund/hisDelegationOrderQueryFund';
import {transactionDetails} from './redux/contractualTransactions/transactionDetails/transactionDetails';
import {transactionDetailsFund} from './redux/contractualTransactions/transactionDetailsFund/transactionDetailsFund';
import {riskRateRules} from './redux/contractualTransactions/riskRateRules/riskRateRules';
import {contractRules} from './redux/contractualTransactions/contractRules/contractRules';
import {contractManagement} from './redux/contractualTransactions/contractManagement/contractManagement';
import {contractMarket} from './redux/contractualTransactions/contractMarket/contractMarket';
import {positionHandlingFee} from './redux/contractualTransactions/positionHandlingFee/positionHandlingFee';
import {riskRateSnapshot} from './redux/contractualTransactions/riskRateSnapshot/riskRateSnapshot';
import {platformEverydayRecord} from './redux/contractualTransactions/platformEverydayRecord/platformEverydayRecord';
import {userHistoryRecord} from './redux/contractualTransactions/userHistoryRecord/userHistoryRecord';
import {openSpreadRobot} from './redux/contractualTransactions/openSpreadRobot/openSpreadRobot';
import {closeSpreadRobot} from './redux/contractualTransactions/closeSpreadRobot/closeSpreadRobot';

// 杠杆交易
import {lineTrdaingRiskRate} from './redux/lineTrdaing/riskRate/riskRate';
import {lineBorrowingRules} from './redux/lineTrdaing/borrowingRules/borrowingRules';
import {lineOrderInDebit} from './redux/lineTrdaing/orderInDebit/orderInDebit';
import {lineInterestDetails} from './redux/lineTrdaing/interestDetails/interestDetails';
import {lineTradingRules} from './redux/lineTrdaing/tradingRules/tradingRules';
import {lineAccountRateRisk} from './redux/lineTrdaing/accountRateRisk/accountRateRisk';

// 社区管理
import {postManagement} from './redux/community/postManagement/postManagement';
import {commentManagement} from './redux/community/commentManagement/commentManagement';
import {focusManagement} from './redux/community/focusManagement/focusManagement';
import {keywordManagement} from './redux/community/keywordManagement/keywordManagement';

// 上币管理
import {currencyApply} from './redux/currencyManagement/apply/apply';
import {votingRecords} from './redux/currencyManagement/votingRecords/votingRecords';
import {voteRules} from './redux/currencyManagement/voteRules/voteRules';

// 基金管理
import {fundManagementFundQuery} from './redux/fundManagement/platformFunds/fundQuery';
import {fundManagementReturnsConfiguration} from './redux/fundManagement/platformFunds/returnsConfiguration';
import {fundManagementCostShare} from './redux/fundManagement/platformFunds/costShare';
import {fundManagementShareValue} from './redux/fundManagement/platformFunds/shareValue';
import {fundManagementDividendIncome} from './redux/fundManagement/platformFunds/dividendIncome';
import {fundManagementCheckDetails} from './redux/fundManagement/platformFunds/checkDetails';
import {platformFundsPurchaseRecords} from './redux/fundManagement/platformFunds/purchaseRecords';
import {platformFundsRedemptionRecord} from './redux/fundManagement/platformFunds/redemptionRecord';

import {fundManagementQueryFund} from './redux/fundManagement/userFund/queryFund';
import {fundManagementPurchaseRecords} from './redux/fundManagement/userFund/purchaseRecords';
import {fundManagementPositionsQuery} from './redux/fundManagement/userFund/positionsQuery';
import {fundManagementRedemptionRecord} from './redux/fundManagement/userFund/redemptionRecord';
import {fundManagementToIncrease} from './redux/fundManagement/userFund/toIncrease';
import {fundManagementFundHoldings} from './redux/fundManagement/userFund/fundHoldings';
import {fundManagementTextManagement} from './redux/fundManagement/configurationFund/textManagement';
import {fundManagementFundDescription} from './redux/fundManagement/configurationFund/fundDescription';

// 查询用户地址
import {userCustomerFinanceAddress} from './redux/user/customer/finance/financeAddress';

// 解锁对象币种
import {unlockBusinessUnlockObject} from './redux/trade/unlockBusiness/unlockObject';
import {unlockBusinessUnlockSpot} from './redux/trade/unlockBusiness/unlockSpot';

// 花火矿池
import {marketingPoolAccount} from './redux/marketing/poolAccount/poolAccount';
import {marketingDailyCurrency} from './redux/marketing/dailyCurrency/dailyCurrency';
import {marketingDailyPromotion} from './redux/marketing/dailyPromotion/dailyPromotion';
import {marketingPetardConfiguration} from './redux/marketing/petardConfiguration/petardConfiguration';
import {marketingCashBenefits} from './redux/marketing/CashBenefits/CashBenefits';
import {marketingDailySnapshot} from './redux/marketing/dailySnapshot/dailySnapshot';
import {marketingCashWelfare} from './redux/marketing/cashWelfare/cashWelfare';
import {marketingIncomeDistribution} from './redux/marketing/dailyCurrency/incomeDistribution';
import {marketingCashDailySnapshot} from './redux/marketing/CashBenefits/cashDailySnapshot';

// 系统配置
import {publicAppConfig} from './redux/public/appConfig';
import {publicH5Config} from './redux/public/h5Config';
import {publicOssConfig} from './redux/public/ossConfig';

// 用户消息
import {publicNoticeUser} from './redux/public/noticeUser';

// 外部转账管理
import {externalInternalTransferEx} from './redux/externalTransferManagement/internalTransferEx';
import {externalInternalTransferSlt} from './redux/externalTransferManagement/internalTransferSlt';
import {externalTransferNotThrough} from './redux/externalTransferManagement/transferNotThrough';
import {externalTransferTransferRules} from './redux/externalTransferManagement/transferRules';
import {externalTransferConfigsRules} from './redux/externalTransferManagement/configsRules';

// 法币交易
// 法币交易-通道交易-支付方式管理
import { tradeBankChannel } from './redux/trade/bankChannel/bankChannel.js';
// 法币交易-通道交易-提现订单
import { tradeBankWithdraw } from './redux/trade/bankWithdraw/bankWithdraw.js';
// 法币交易-通道交易-充币订单
import { tradeBankCharge } from './redux/trade/bankCharge/bankCharge.js';
// 法币交易-银行卡管理-个人银行卡管理
import { tradeBankcard } from './redux/trade/bankcard/bankcard.js';
// 法币交易-通道交易-充值交易设置
import { tradeTradeConfigCharge } from './redux/trade/tradeConfigCharge/tradeConfigCharge.js';
// 法币交易-通道交易-提现交易设置
import { tradeTradeConfigWithdraw } from './redux/trade/tradeConfigWithdraw/tradeConfigWithdraw.js';
// 法币交易-通道交易-每日报表
import { tradeDailyReport } from './redux/trade/dailyReport/dailyReport.js';
// 法币交易-通道交易-充值消息管理
import { tradeRechargeMsg } from './redux/trade/rechargeMsg/rechargeMsg.js';
// 法币交易-通道交易-充值消息管理
import { tradeSalesList } from './redux/trade/saleTrade/salesList.js';

// 营销管理
// 行为挖矿-行为管理管理
import { marketingMinerAction } from './redux/marketing/minerAction/minerAction.js';
// 行为挖矿-矿产区块配置管理
import { marketingMinerBlock } from './redux/marketing/minerBlock/minerBlock.js';
// 行为挖矿-用户获取算力明细管理
import { marketingMinerCalculate } from './redux/marketing/minerCalculate/minerCalculate.js';
// 行为挖矿-矿产区块配置管理
import { marketingMinerPool } from './redux/marketing/minerPool/minerPool.js';
// 行为挖矿-区块明细管理
import { marketingMinerToken } from './redux/marketing/minerToken/minerToken.js';
// 运维管理-链查询
import { publicChainQuery } from './redux/public/chainQuery.js';

// 文章管理-文章分类
import { publicArticleClass } from './redux/public/articleClass.js';
// 文章管理-文章管理
import { publicCustomerService } from './redux/public/customerService.js';
// 客户管理-kyc配置
import { userKycConfig } from './redux/user/customer/kycConfig';

import { userCustomerWtAccount } from './redux/user/customer/wtAccount';
import { userCustomerLineCustomer } from './redux/user/customer/lineCustomer';
import { userCustomerStockCustomer } from './redux/user/customer/stockCustomer';
import { userCustomerMarketingCustomer } from './redux/user/customer/marketingCustomer';

// 新手任务-任务规则
import { newbieTaskRules } from './redux/marketing/newbieTaskRules/newbieTaskRules';

// 法币交易-银行卡管理-个人银行卡管理
import { tradeBankcardCheck } from './redux/trade/bankcardCheck/bankcardCheck.js';
// 法币交易-银行卡管理-个人银行卡管理
import { tradeBankcardNopassQuery } from './redux/trade/bankcardNopassQuery/bankcardNopassQuery.js';
// 群发邮件
import {publicEmail} from './redux/public/email';

// 机器人
import { robotTypeList } from './redux/robot/typeList.js';
import { earningsRankList } from './redux/robot/earningsRankList.js';
import { robotHave } from './redux/robot/haveRobot.js';
import { haveRobotDetail } from './redux/robot/haveRobotDetail.js';
import { robotGrade } from './redux/robot/robotGrade.js';
import { aiStatistic } from './redux/robot/aiStatistic.js';
import { activityList } from './redux/activityManager/activityList';
export default combineReducers({
    user,
    menu,
    message,
    modalDetail,
    systemRole,
    systemMenu,
    systemUser,
    systemPwdReset,
    systemSysParam,
    systemDataDict,
    publicCommunityAddEdit,
    // 二元期权
    businessSimulateList,
    businessFirmlateList,
    businessSampleList,
    historySimulateList,
    historyFirmlateList,
    historySampleList,
    varietyGrouping,
    broadcastMsg,
    feed,
    varietyOdds,
    // 机器人
    robotTypeList,
    earningsRankList,
    robotHave,
    haveRobotDetail,
    robotGrade,
    aiStatistic,
    // public
    publicAboutus,
    publicQuantitative,
    publicContact,
    publicMachineProtocol,
    publicBuyADS,
    publicSellETH,
    publicTransactionRemind,
    publicCommunity,
    publicNotice,
    publicNoticeAddEdit,
    publicFirebase,
    publicFirebaseAddEdit,
    publicWarmReminding,
    publicLegalDeclaration,
    publicRateExplain,
    publicLhlcxy,
    // 会员查询
    userCustomer,
    userCustomerAccount,
    userCustomerEntrustQuery,
    userCustomerLedgerQuery,
    userCustomerAccountSummary,
    userCustomerIdentify,
    commissionRefund,
    // 黑名单管理
    userCustomerBlackList,
    // kyc审核
    userKycCheck,
    // 资料审核
    userDataCheck,
    // 历史分红名单
    userHistoryDivideList,
    userHistoryDivideListDivideList,
    // 佣金结算历史
    userCommissionsHistoryList,
    userCommissionsHistoryListCommissions,
    // 业务管理-账号管理
    accountMarketmakers,
    accuntMarketmakersLowerLevelQuery,
    businessProjectManage,
    businessOrderInquiry,
    businessHolidaySeason,
    businessTradingRules,
    businessTradingRulesFund,
    businessHisTradingRules,
    businessHisTradingRulesFund,
    businessLineTradingRules,
    businessLineTradingRulesFund,
    businessLineRules,
    businessLineRulesFund,
    businessTransactionOrderInquiry,
    businessTransactionOrderInquiryFund,
    businessLineOrderInquiry,
    businessLineOrderInquiryFund,
    businessTradingSet,
    purchasePurchase,
    purchasePurchaseRecord,
    purchaseSettlementRecord,
    businessPurchaseRule,
    deliveryRealRight,
    deliveryDelivery,
    deliveryDeliveryRecord,
    businessDeliveryRule,
    deliverys,
    // 广告位管理
    // public
    publicBanner,
    publicBannerAddEdit,
    publicPointPostion,
    // 币种管理
    publicSectorManagement,
    publicCurrencyManagement,
    publicMarketManagement,
    publicTransactionPair,
    // 待结算佣金名单
    userChannelDealerCommissions,
    userChannelDealerCommissionsChecklist,
    userChannelDealerCommissionsSettlement,
    // 待结算佣金名单
    userChannelDealerSettleHistory,
    userChannelDealerSettleHistoryCommissions,
    // OTC交易
    tradeBuyTrade,
    tradeSaleTrade,
    tradeUnderWayOrder,
    tradeFinishOrder,
    tradeArbitrationOrder,
    tradeArbitrationNotifier,
    tradeConfiguration,
    textConfigConfiguration,
    UserStatistics,
    InUserStatistics,
    userStatisticsSummarize,
    userStatisticsOrderStatistics,
    userVirtualUserPool,
    tradeAccount,
    externalOrder,
    externalHistoricalOrder,
    successfulOrder,
    defaultCommit,
    // 活动管理
    // 业务规则
    // 广告费规则
    rulesAdvertisingFee,
    // 币币交易手续费规则
    // 提币手续费规则
    BTCFinanceWithdrawRule,
    // 业务管理 评论管理
    commentKeywords,
    commentCheck,
    commentList,
    // 应用列表管理
    bizApplicationList,
    bizApplicationListHelpSet,
    // 红包管理
    bizReceiveQuery,
    bizRedPacketQuery,
    operationsFeatureItem,
    /* BTC 财务管理 */
    // 查询币种
    addressQuery,
    // 平台账户
    BTCFinancePlatformAccount,
    // 分发地址
    BTCFinanceDiviAddress,
    BTCFinanceStockLockCustomer,
    BTCFinanceLineCustomer,
    BTCFinanceFinancialAccount,
    BTCFinanceDiviAddressLedger,
    // 充币管理
    BTCFinanceOfflineRecharge,
    BTCFinanceOfflineRechargeAddEdit,
    BTCFinanceOfflineRechargeDetail,
    BTCFinanceOfflineRechargeQuery,
    // 提币管理
    BTCFinanceTBAddress,
    TradeArbitrationNotifier,
    BTCFinanceTBunderline,
    BTCFinanceTBunderlineAddEdit,
    BTCFinanceTBunderlineMultiCheck,
    // 归集管理
    BTCFinanceGJAddress,
    BTCFinanceGJAddressAddEdit,
    BTCFinanceGJAddressQuery,
    BTCFinanceGJAddressQueryAddEdit,
    BTCFinanceSupplyAddress,
    publicOtcPayment,
    publicOtcCountry,
    publicChannelBank,
    // 内部转账管理
    internalTransferEx,
    internalTransferSlt,
    transferNotThrough,
    TransferTransferRules,
    // 业务管理--OTC管理
    OtcManageAdvert,
    OtcSurvivaLorDer,
    OtcSurvivaLorDerOrderedit,
    OtcManageHistoricalorder,
    OtcManageComment,
    OtcManageCurrencyRules,
    // 营销结算
    StadySettLement,
    OtcManageHistoricalorderEdit,
    NoOrder,
    StayOrder,
    AlreadySettlement,
    quanTitativeCategory,
    // 统计分析-定时器管理
    timerMonitor,
    timerPerformance,
    // 法币汇率管理
    legalTender,
    legalTenderHistory,
    bigManagementDigSet,
    bigManagementDigBase,
    bigManagementDigRecord,
    digRecordDetailList,
    bigManagementDigLicense,
    bigManagementDigDelivery,
    bigManagementCalculate,
    bigManagementTokensAList,
    marketingWillExamination,
    marketingThroughExamination,
    marketingNotThroughExamination,
    marketingLqqRobot,
    marketingSyrfp,
    orePondManagement,
    userOrePondManagement,
    purchaseRecordTwo,
    activityList,
    // 业务统计
    latformDaily,
    userDaily,
    latformDailyCoin,
    userDailyCoin,
    latformDailyTrade,
    userDailyTrade,

    latformDailyNew,
    userDailyNew,
    latformDailyCoinNew,
    userDailyCoinNew,
    userDailyTradeNew,
    payChargeDailyNew,
    configurationList,
    // API管理
    apikeyManager,
    fixedDeposit,
    supply,
    klineGroup,
    maintainDetails,
    executeInstruction,
    robotManagementCommissionOrder,
    robotManagementCommissionOrder2,
    robotManagementShoppingSpree,
    orderInquiry,
    // 合约交易
    contractAccount,
    currentBinQuery,
    historicalBinQuery,
    delegationOrderQuery,
    delegationOrderQueryFund,
    hisDelegationOrderQuery,
    hisDelegationOrderQueryFund,
    transactionDetails,
    transactionDetailsFund,
    riskRateRules,
    contractRules,
    contractManagement,
    contractMarket,
    positionHandlingFee,
    riskRateSnapshot,
    platformEverydayRecord,
    userHistoryRecord,
    openSpreadRobot,
    closeSpreadRobot,
    // 杠杆交易
    lineTrdaingRiskRate,
    lineBorrowingRules,
    lineOrderInDebit,
    lineInterestDetails,
    lineTradingRules,
    lineAccountRateRisk,
    // 社区管理
    postManagement,
    commentManagement,
    focusManagement,
    keywordManagement,
    // 上币申请
    voteRules,
    currencyApply,
    votingRecords,
    fundManagementFundQuery,
    fundManagementReturnsConfiguration,
    fundManagementCostShare,
    fundManagementShareValue,
    fundManagementDividendIncome,
    fundManagementCheckDetails,
    platformFundsRedemptionRecord,
    platformFundsPurchaseRecords,
    fundManagementQueryFund,
    fundManagementPurchaseRecords,
    fundManagementPositionsQuery,
    fundManagementRedemptionRecord,
    fundManagementTextManagement,
    fundManagementToIncrease,
    fundManagementFundHoldings,
    fundManagementFundDescription,
    userCustomerFinanceAddress,
    unlockBusinessUnlockObject,
    unlockBusinessUnlockSpot,
    marketingPoolAccount,
    marketingDailyCurrency,
    marketingDailyPromotion,
    marketingPetardConfiguration,
    marketingCashBenefits,
    marketingDailySnapshot,
    marketingCashWelfare,
    marketingIncomeDistribution,
    marketingCashDailySnapshot,
    // 系统配置
    publicAppConfig,
    publicH5Config,
    publicOssConfig,
    publicNoticeUser,
    // 外部转账管理
    externalInternalTransferEx,
    externalInternalTransferSlt,
    externalTransferNotThrough,
    externalTransferTransferRules,
    externalTransferConfigsRules,
    // 通道交易
    tradeBankChannel,
    tradeBankWithdraw,
    tradeBankCharge,
    tradeSalesList,
    tradeBankcard,
    tradeTradeConfigCharge,
    tradeTradeConfigWithdraw,
    tradeDailyReport,
    tradeRechargeMsg,
    // 行为挖矿
    marketingMinerAction,
    marketingMinerBlock,
    marketingMinerCalculate,
    marketingMinerPool,
    marketingMinerToken,
    publicChainQuery,
    publicArticleClass,
    publicCustomerService,
    userKycConfig,
    userCustomerWtAccount,
    userCustomerLineCustomer,
    userCustomerStockCustomer,
    userCustomerMarketingCustomer,
    newbieTaskRules,
    tradeBankcardCheck,
    tradeBankcardNopassQuery,
    publicEmail
});
